/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState, useMemo, useCallback } from 'react';
import ReactMapboxGl, { Feature, Layer, Marker, Source } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { observer } from 'mobx-react-lite';
import { Node } from '../../components/map/node/node';
import { useAppStore } from '../../store/app';
import mapBg from '../../assets/map.jpg';

const Mapbox = ReactMapboxGl({
  accessToken:
    'pk.eyJ1Ijoic3BvcnRtYXN0ZXJnYW1lIiwiYSI6ImNrcTlmY25ocDBwaGgycG12dWI0ZHhlbXQifQ.-oSuGs6ZdToIRgZweFo__A',
  logoPosition: 'bottom-right',
  minZoom: 2,
  maxZoom: 3.5,
});

const mapboxStyle = {
  height: '100vh',
  width: '100vw',
  minWidth: '100%',
};
const west = -100;
const east = 100;
const south = -54;
const north = 82;
const maxBounds = [
  [west, south],
  [east, north],
];
const mapCenter = [1.8444266238965668, 45.56030668075289];

export const GameMap = observer(() => {
  const {
    nodes,
    openActionModal,
    gameData,
    showCoordinates,
    getTeamColorById,
    isActive,
  } = useAppStore();
  const [highlitedCity, setHighlitedCity] = useState({
    id: null,
    links: [],
    features: [],
  });
  const [markerScale, setMarkerScale] = useState(1);

  const onLoad = useCallback(map => {
    map.setZoom(2.8);
    map.touchPitch.disable();
    map.dragRotate.disable();
  }, []);

  const handleNodeClick = useCallback(id => {
    openActionModal(id);
  }, []);

  const handleNodeMouseOver = useCallback(
    (id, routes, features) =>
      setHighlitedCity({
        id,
        links: routes,
        features,
      }),
    []
  );

  const handleNodeMouseOut = useCallback(
    () => setHighlitedCity({ id: null, links: [], features: [] }),
    []
  );

  const handleMapZoom = useCallback(
    map => setMarkerScale(map.getZoom() > 3 ? 2 : 1),
    [setMarkerScale]
  );

  const lines = useMemo(() => {
    const allLines = nodes
      .map(({ lat, lon, routes }) => {
        const features = routes.map(linkId => {
          const linkedNode = nodes.find(item => item.id === linkId);
          return linkedNode
            ? [
                [lon, lat],
                [linkedNode.lon, linkedNode.lat],
              ]
            : null;
        });
        return features;
      })
      .flat();
    const linesCache = new Map();
    return allLines.filter(item => {
      if (!item) {
        return false;
      }

      const linePoints = item.map(i => i.join());
      linesCache.set(linePoints.join(), item);
      const reversedLinePoints = linePoints.reverse().join();

      return !linesCache.get(reversedLinePoints);
    });
  }, [nodes]);
  const RASTER_SOURCE_OPTIONS = {
    type: 'image',
    url: mapBg,
    coordinates: [
      [west, north],
      [east, north],
      [east, south],
      [west, south],
    ],
  };
  const onMapClick = (_map, event) => {
    alert(`долгота: ${event.lngLat.lng}, широта: ${event.lngLat.lat}`);
  };

  return (
    <Mapbox
      // eslint-disable-next-line react/style-prop-object
      style="mapbox://styles/sportmastergame/ckrg0xq9y4yap17o5tizr575f"
      containerStyle={mapboxStyle}
      onStyleLoad={onLoad}
      renderChildrenInPortal
      maxBounds={maxBounds}
      onClick={showCoordinates ? onMapClick : undefined}
      center={mapCenter}
      onZoom={handleMapZoom}
    >
      <Source id="source_id" tileJsonSource={RASTER_SOURCE_OPTIONS} />
      <Layer type="raster" id="layer_id" sourceId="source_id" />
      {nodes.map(
        ({
          id,
          name,
          lat,
          lon,
          level,
          owner,
          routes,
          money,
          canBeCaptured,
          isControl,
          availableMoney,
        }) => {
          const isHighlighted = highlitedCity.id === id;
          const features = routes.map(linkId => {
            const linkedNode = nodes.find(item => item.id === linkId);
            return linkedNode
              ? [
                  [lon, lat],
                  [linkedNode.lon, linkedNode.lat],
                ]
              : [];
          });
          const isOwnNode = gameData.team_id === owner;
          const isActiveNode = level > 0 && isOwnNode;
          return (
            <Marker
              key={id}
              coordinates={[lon, lat]}
              anchor="center"
              style={{
                zIndex: level > 0 ? 5 : 3,
              }}
            >
              <Node
                id={id}
                label={name}
                onClick={isActiveNode && isActive ? handleNodeClick : undefined}
                onMouseOver={() => handleNodeMouseOver(id, routes, features)}
                onMouseOut={handleNodeMouseOut}
                isHighlighted={
                  isHighlighted || highlitedCity.links.includes(id)
                }
                level={level}
                color={getTeamColorById(owner)}
                price={isOwnNode ? availableMoney : money}
                isOwnNode={isOwnNode}
                isStartNode={!canBeCaptured}
                isControlPoint={isControl}
                scale={markerScale}
              />
            </Marker>
          );
        }
      )}
      <Layer
        type="line"
        paint={{
          'line-color': '#0D21D0',
          'line-width': 20,
          'line-opacity': 0.7,
          'line-blur': 17,
        }}
      >
        {highlitedCity.features.map(item => {
          return <Feature key={item[0]} coordinates={item} />;
        })}
      </Layer>
      <Layer
        type="line"
        paint={{
          'line-color': '#0D21D0',
          'line-width': 2,
          'line-opacity': 0.6,
          'line-dasharray': [2, 2],
        }}
      >
        {lines.map(item => {
          return <Feature key={item[0]} coordinates={item} />;
        })}
      </Layer>
    </Mapbox>
  );
});
