import { observer } from 'mobx-react-lite';
import React from 'react';

import styles from './game.module.css';
import Header from '../header/header';
import { ActionModal } from '../action-modal/action-modal';
import { NewsModal } from '../news-modal/news-modal';
import { ChatModal } from '../chat-modal/chat-modal';
import { HelpModal } from '../help-modal/help-modal';
import { StatisticModal } from '../statistic-modal/statistic-modal';
import { Round } from '../../components/round/round';
import { useAppStore } from '../../store/app';
import { GameMap } from '../game-map/game-map';
import { NextRoundModal } from '../next-round-modal/next-round-modal';
import { WelcomeModal } from '../welcome-modal/welcome-modal';
import { Clouds } from '../../components/clouds/clouds';
import { FinalModal } from '../final-modal/final-modal';

export const Game = observer(() => {
  const { round, selectedNode } = useAppStore();
  return (
    <div>
      <Clouds />
      <div className={styles.headerShadow} />
      <div className={styles.header}>
        <Header />
      </div>
      <div className={styles.field}>
        <GameMap />
        {selectedNode && <ActionModal />}
        <NewsModal />
        <ChatModal />
        <HelpModal />
        <StatisticModal />
        <NextRoundModal />
        <WelcomeModal />
        <FinalModal />
      </div>
      {round > 0 && (
        <div className={styles.period}>
          <Round round={round} />
        </div>
      )}
    </div>
  );
});
