import React from 'react';
import PropTypes from 'prop-types';

export const NodeControlFlag = ({
  color = '#83745F',
  isScale2x = false,
  className,
}) => {
  return (
    <i className={className}>
      <svg
        width={isScale2x ? 154 : 124}
        height={isScale2x ? 101 : 81}
        viewBox="0 0 154 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M130.936 33.5232l11.227-29.86297c.584-1.56588-1.004-2.978773-3.028-2.687323L83.0857 9.3734l-16.8919 2.432c-3.1129.4481-5.3508 2.7765-5.0014 5.2036.3494 2.4271 3.1533 4.0296 6.2662 3.5814l7.5856 52.6881L147.985 62.446c2.025-.2914 3.149-2.0948 2.148-3.4324l-19.197-25.4904z"
          fill={color}
        />
        <path
          d="M130.936 33.5232l11.227-29.86297c.584-1.56588-1.004-2.978773-3.028-2.687323L83.0857 9.3734l-16.8919 2.432c-3.1129.4481-5.3508 2.7765-5.0014 5.2036.3494 2.4271 3.1533 4.0296 6.2662 3.5814l7.5856 52.6881L147.985 62.446c2.025-.2914 3.149-2.0948 2.148-3.4324l-19.197-25.4904z"
          fill="#000"
          fillOpacity=".27"
        />
        <path
          d="M83.1968 90.0239l-66.927 9.9991c-2.1719.325-3.9449-1.7175-3.3188-3.8223l7.634-25.6606c.2414-.8116.1286-1.6875-.3106-2.4114L5.09691 43.1108c-1.11184-1.8327.00156-4.2064 2.1216-4.5231l64.94979-9.7038c1.2107-.1809 2.0478-1.3059 1.8734-2.5175-.1751-1.2162.6689-2.344 1.8851-2.5191l.6147-.0885c1.5558-.224 2.9985.8556 3.2225 2.4113l8.4438 58.6488c.3399 2.4284-1.898 4.7568-5.011 5.205z"
          fill={color}
        />
        <path
          d="M83.1968 90.0239l-66.927 9.9991c-2.1719.325-3.9449-1.7175-3.3188-3.8223l7.634-25.6606c.2414-.8116.1286-1.6875-.3106-2.4114L5.09691 43.1108c-1.11184-1.8327.00156-4.2064 2.1216-4.5231l64.94979-9.7038c1.2107-.1809 2.0478-1.3059 1.8734-2.5175-.1751-1.2162.6689-2.344 1.8851-2.5191l.6147-.0885c1.5558-.224 2.9985.8556 3.2225 2.4113l8.4438 58.6488c.3399 2.4284-1.898 4.7568-5.011 5.205z"
          fill="url(#NodeControlFlag_paint0_linear)"
          fillOpacity=".2"
        />
        <path
          d="M61.1906 17.0092c.3494 2.427 3.1533 4.0296 6.2662 3.5814l5.6338-.8111c3.1129-.4482 5.9168 1.1543 6.2662 3.5814.3494 2.4271-1.8885 4.7555-5.0014 5.2036l-11.2676 1.6223-1.8972-13.1776z"
          fill={color}
        />
        <path
          d="M61.1906 17.0092c.3494 2.427 3.1533 4.0296 6.2662 3.5814l5.6338-.8111c3.1129-.4482 5.9168 1.1543 6.2662 3.5814.3494 2.4271-1.8885 4.7555-5.0014 5.2036l-11.2676 1.6223-1.8972-13.1776z"
          fill="#000"
          fillOpacity=".46"
        />
        <defs>
          <linearGradient
            id="NodeControlFlag_paint0_linear"
            x1="49.7903"
            y1="27.6102"
            x2="59.2721"
            y2="93.4684"
            gradientUnits="userSpaceOnUse"
          >
            <stop />
            <stop offset="1" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </i>
  );
};

NodeControlFlag.propTypes = {
  color: PropTypes.string.isRequired,
  isScale2x: PropTypes.bool,
  className: PropTypes.string,
};
