import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import classes from './restore-session.module.css';

function RestoreSession({ userName, submit }) {
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <Button
        className={classes.button}
        variant="outline-primary"
        type="submit"
        onClick={submit}
        block
      >
        {t('game.auth.enterAs', { name: userName })}
      </Button>
    </div>
  );
}

RestoreSession.propTypes = {
  userName: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
};

export default observer(RestoreSession);
