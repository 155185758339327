import React from 'react';
import PropTypes from 'prop-types';
import { Spinner as BootstrapSpinner } from 'react-bootstrap';
import cn from 'classnames';

import classes from './spinner.module.css';

export const Spinner = ({ className }) => (
  <div className={cn(classes.spinner, className)}>
    <BootstrapSpinner animation="border" role="status">
      <span className="sr-only">Загрузка данных...</span>
    </BootstrapSpinner>
  </div>
);

Spinner.propTypes = {
  className: PropTypes.string,
};
