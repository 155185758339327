import React from 'react';

export const InvestIcon = () => (
  <i>
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5776 7.36595c-.87668 0-1.77585-.33-2.45752-.98584-.71418-.68584-.88751-1.55168-1.07085-2.46835C3.8459 2.89175 3.63506 1.83674 2.62756.868397 2.51089.755896 2.47005.585061 2.52255.43256c.05334-.154168.19084-.263336.35251-.278336l.11416-.011667C5.05924-.0682782 7.6351-.32328 9.65845 1.6209c.64085.61501 1.02835 1.55918 1.03755 2.52336.0091.91834-.3134 1.75168-.90838 2.34502-.58584.58584-1.38751.87667-2.21002.87667zM3.7709.90423c.72.96001.91751 1.95168 1.09584 2.84503.16334.8175.30417 1.52501.83001 2.03085.89251.85667 2.51252 1.11084 3.50086.12166.43501-.43417.67084-1.05584.66417-1.74834-.0075-.74584-.29916-1.46752-.78167-1.93085C7.53593.738395 5.58425.744229 3.7709.90423zM15.0143 5.83508c-.0583 0-.1175-.00166-.1758-.00583-.6767-.04-1.2642-.30834-1.655-.75584-1.1133-1.27418-.745-3.01503.235-3.89753C15.071-.311638 17.0935-.0466358 18.7169.167533l.0875.011667c.1608.020833.295.134167.3442.290002.0483.155835.0016.325003-.1192.434171-.785.706677-.9717 1.505847-1.1517 2.278357-.1608.68917-.3267 1.40168-.9208 1.93668-.5067.455-1.2225.71667-1.9426.71667zM16.7744.834205c-.9834 0-1.9609.205835-2.7992.961675-.6867.61751-.9442 1.83668-.165 2.72836.2433.27833.6258.44584 1.0775.4725.5566.02917 1.125-.15417 1.5108-.5.4-.36001.5184-.86584.6675-1.50668.1459-.62751.32-1.37168.84-2.085021-.3725-.04-.7533-.070834-1.1316-.070834zm-3.0776.651675h.0084-.0084z"
        fill="#fff"
      />
      <path
        d="M11.3576 10.0518c-.1967 0-.3725-.13997-.4092-.34081-.5483-2.96252-3.36499-5.40171-3.39416-5.42588-.175-.14916-.195-.4125-.04583-.58667.14916-.17583.41167-.1975.5875-.04667.125.10667 3.06919 2.65003 3.67249 5.90756.0417.22583-.1075.44337-.3341.48497-.0259.005-.0509.0075-.0767.0075zM2.91669 18.3351H.41667C.186668 18.3351 0 18.1484 0 17.9184v-7.5c0-.23.186668-.4167.41667-.4167 2.83419 0 4.3317.955 4.39421.9959.14916.0975.22.2791.17666.4516l-1.66668 6.5717c-.0475.185-.21333.3142-.40417.3142zm-2.083349-.8333H2.59252l1.50752-5.9417c-.46917-.2242-1.57418-.655-3.266699-.7176v6.6593z"
        fill="#fff"
      />
      <path
        d="M10.8335 20.0019c-1.96672 0-5.76926-1.6533-7.86177-2.64-.20834-.0975-.29667-.3467-.19917-.5542.0975-.2091.34667-.2966.555-.1991 1.63335.77 5.63338 2.56 7.50594 2.56 2.0266 0 6.8042-2.3809 8.1784-3.0884-.2167-.2833-.6642-.6617-1.5117-.6617-.7692 0-1.7442.3075-2.6875.6059-1.0025.3158-2.0401.6442-2.9376.6442-1.72 0-4.00416-.8234-4.10083-.8584-.21584-.0783-.32751-.3175-.24917-.5342.07833-.2158.31667-.3266.53417-.2491.02167.0083 2.24333.8083 3.81583.8083.7692 0 1.7442-.3075 2.6876-.6058 1.0025-.3159 2.04-.6442 2.9375-.6442 1.9442 0 2.4575 1.4725 2.4783 1.535.0642.1925-.0191.4034-.1991.5-.2592.1367-6.3717 3.3817-8.9459 3.3817z"
        fill="#fff"
      />
      <path
        d="M13.7502 16.3268c-.23 0-.4167-.1867-.4167-.4167v-.4167c0-.5817-.3917-1.08-.9533-1.21l-8.04509-1.9425c-.22417-.0542-.36167-.2792-.30751-.5033.05334-.225.28251-.3576.50251-.3076l8.04169 1.9417c.9384.2192 1.5951 1.0509 1.5951 2.0217v.4167c0 .23-.1867.4167-.4167.4167zM11.3584 10.0501h-.0025c-.2291-.0017-.415-.1875-.4141-.41667 0-.14417.0525-3.5392 3.8433-5.82172.1967-.11833.4525-.05583.5717.1425.1192.19667.055.45334-.1417.57251-3.3725 2.03085-3.4383 4.98587-3.4392 5.11088-.0025.2275-.1883.4125-.4175.4125z"
        fill="#fff"
      />
    </svg>
  </i>
);
