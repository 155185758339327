import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';

import { useAppStore } from '../../store/app';
import { APP_STATES } from '../../constants';
import { PageSpinner } from '../../components/page-spinner/page-spinner';
import Auth from '../Auth';
import classes from './main-page.module.css';
import Error from '../../components/error/error';
import { Game } from '../../modules/game/game';
import { Clouds } from '../../components/clouds/clouds';

function MainPage() {
  const { state, error, fetching } = useAppStore();
  return (
    <div className={classes.wrapper}>
      {state !== APP_STATES.SIGNED_IN && <Clouds />}
      <div className={classes.wrapperBg}>
        <div className={cn(classes.contentContainer, classes.bg)}>
          {(state === APP_STATES.INITIAL || fetching) && <PageSpinner />}
          {state === APP_STATES.CHECKED && <Auth />}
          {state === APP_STATES.SIGNED_IN && <Game />}
          {state === APP_STATES.ERROR && (
            <Error message={error.reason} title={error.header} />
          )}
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
}

export default observer(MainPage);
