import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';

import App from './App.jsx';
import i18n from './i18n';
import { Spinner } from './components/spinner/spinner.jsx';

ReactDOM.render(
  <Suspense fallback={<Spinner />}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Suspense>,
  document.getElementById('app')
);
