import { api } from '.';

export const addAction = (token, type, nodeId, targetId, value, label) =>
  api.get('/action', {
    params: {
      token,
      type,
      node_id: nodeId,
      target_id: targetId,
      value,
      label,
    },
  });
