import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { ArrowDownIcon } from '../../icons/arrow-down/arrow-down';

import styles from './input-counter.module.css';

export const InputCounter = ({
  name,
  min = 0,
  max = Infinity,
  step = 10,
  onChange,
  initialValue,
}) => {
  const [value, setValue] = useState(initialValue || 0);
  const handleDownClick = () => setValue(value - step);
  const handleUpClick = () => setValue(value + step);
  const handleChange = event => {
    const parsedValue = parseInt(event.target.value || 0, 10);
    if (parsedValue > max) {
      setValue(max);
    } else if (parsedValue < min) {
      setValue(min);
    } else {
      setValue(parsedValue);
    }
  };

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <div className={styles.inputContainer}>
      <input
        className={styles.input}
        name={name}
        value={value}
        min={min}
        max={max}
        onChange={handleChange}
      />
      <button
        className={cn(styles.arrow, styles.arrowUp)}
        type="button"
        onClick={handleUpClick}
        disabled={value + step > max}
      >
        <span className={cn(styles.icon, styles.iconUp)}>
          <ArrowDownIcon />
        </span>
      </button>
      <button
        className={cn(styles.arrow, styles.arrowDown)}
        type="button"
        onClick={handleDownClick}
        disabled={value - step < min}
      >
        <span className={cn(styles.icon)}>
          <ArrowDownIcon />
        </span>
      </button>
    </div>
  );
};

InputCounter.propTypes = {
  name: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  initialValue: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};
