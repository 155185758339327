/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './node.module.css';
import { EmptyNode } from '../empty-node/empty-node';
import { NodeWithLevel } from '../node-with-level/node-with-level';

export const Node = ({
  id,
  label,
  onClick,
  level,
  defense,
  price,
  color,
  onMouseOver,
  onMouseOut,
  isHighlighted,
  isOwnNode,
  isStartNode,
  isControlPoint,
  scale = 1,
}) => {
  const NodeComponent = level > 0 ? NodeWithLevel : EmptyNode;
  const handleClick = () => (onClick ? onClick(id) : undefined);
  return (
    <button
      type="button"
      className={cn(styles.node, {
        [styles.unclickable]: !onClick,
        [styles.own]: isOwnNode,
        [styles.highlighted]: isHighlighted,
      })}
      onClick={handleClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <NodeComponent
        label={label}
        price={price}
        color={color}
        level={level}
        defense={defense}
        isHighlighted={isHighlighted}
        isOwnNode={isOwnNode}
        isStartNode={isStartNode}
        isControlPoint={isControlPoint}
        scale={scale}
      />
    </button>
  );
};

Node.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func.isRequired,
  onMouseOut: PropTypes.func.isRequired,
  level: PropTypes.number.isRequired,
  defense: PropTypes.number,
  price: PropTypes.number,
  color: PropTypes.string.isRequired,
  isHighlighted: PropTypes.bool,
  isOwnNode: PropTypes.bool,
  isStartNode: PropTypes.bool,
  isControlPoint: PropTypes.bool,
  scale: PropTypes.number,
};
