/* eslint-disable camelcase */
import React from 'react';

import { action, runInAction, observable, computed, reaction } from 'mobx';
import io from 'socket.io-client';
import { check } from '../api/check';
import { APP_STATES, WEBSOCKET_API_URL } from '../constants';
import { connect } from '../api/connect';
import { setStorageItem, getStorageItem } from '../helpers';
// import { ResultsStore } from './results';
import { game } from '../api/game';
import { news } from '../api/news';
import { addAction } from '../api/add-action';
import { removeAction } from '../api/remove-action';
import { stat } from '../api/stat';
import { actions } from '../api/actions';
import { ChatStore } from './chat';
import { isFatalError } from './helpers/error';

const monthesMap = {
  1: 7,
  2: 8,
  3: 9,
  4: 10,
  5: 11,
  6: 12,
  7: 1,
  8: 2,
  9: 3,
  10: 4,
  11: 5,
  12: 6,
  13: 7,
  14: 8,
  15: 9,
  16: 10,
  17: 11,
  18: 12,
  19: 1,
  20: 2,
  21: 3,
  22: 4,
  23: 5,
  24: 6,
};

/**
 * @typedef {Object} GameData
 * @property {string} name
 * @property {string} team_link
 * @property {string} start_at
 * @property {string} team_name
 * @property {number} team_room
 * @property {string} stream
 * @property {number} rounds
 * @property {number} is_captain
 * @property {number} can_change_captain
 */

/**
 * @typedef {Object} Error
 * @property {string} header
 * @property {string} reason
 */

export class AppStore {
  @observable state = APP_STATES.INITIAL;

  @observable fetching = false;

  /** @type {string} */
  @observable name = null;

  /** @type {GameData} */
  @observable gameData = {};

  /** @type {Error} */
  error = null;

  @observable token = null;

  @observable showModal = null;

  @observable round = 0;

  @observable newsData = [];

  @observable selectedNodeId = null;

  @observable nodes = [];

  @observable commands = [];

  @observable log = [];

  @observable results = [];

  @observable achievements = [];

  @observable crossAchievements = [];

  @observable actives = { actives: [], activesSum: 0 };

  @observable isActive = false;

  showCoordinates = false;

  // commands = observable.array([]);

  constructor(config) {
    const { token, showCoordinates } = config;
    this.setToken(token);
    this.showCoordinates = showCoordinates;

    this.check = this.check.bind(this);

    // this.resultsStore = new ResultsStore(this);
    this.chatStore = new ChatStore(this);
    this.configureSocket();
    this.listenCommonSocket();

    // eslint-disable-next-line no-underscore-dangle
    window.__openModal__ = this.openModal;

    reaction(
      () => this.state,
      state => {
        if (state === APP_STATES.SIGNED_IN) {
          this.saveSession();
          this.configureSocket();
          this.listenSocket();
          this.fetchGame();
          this.fetchNews();
          this.fetchStat();
        }
      }
    );

    reaction(
      () => this.round,
      round => {
        this.openModal(round === 1 ? 'welcome' : 'next-month');
      }
    );
  }

  @computed
  get isAuthorized() {
    return this.state === APP_STATES.SIGNED_IN;
  }

  @computed
  get canChangeCaptain() {
    return Boolean(this.gameData.can_change_captain);
  }

  @computed
  get month() {
    return monthesMap[this.round];
  }

  @computed
  get isCaptain() {
    return this.gameData.is_captain;
  }

  @computed
  get teams() {
    const { teams } = this.gameData || {};
    return teams || [];
  }

  @computed
  get team() {
    return this.teams.find(({ id }) => id === this.gameData.team_id);
  }

  @computed
  get teamName() {
    return this.team.name;
  }

  @computed
  get teamColor() {
    return this.team.color;
  }

  @computed
  get teamColorName() {
    return this.team.colorName;
  }

  @computed
  get teamNames() {
    return this.teams.map(({ name }) => name);
  }

  @computed
  get teamColors() {
    return this.teams.map(({ color }) => color);
  }

  @computed
  get features() {
    const { features } = this.gameData || {};
    return features;
  }

  @computed
  get canCapture() {
    return this.features.actions.capture;
  }

  @computed
  get canDefense() {
    return this.features.actions.defense;
  }

  @computed
  get canInvesting() {
    return this.features.actions.investing;
  }

  @computed
  get canTransfer() {
    return this.features.actions.transfer;
  }

  @computed
  get canUpgrade() {
    return this.features.actions.upgrade;
  }

  @computed
  get canTransferWithoutRoutes() {
    return this.features.transferWithoutRoutes;
  }

  @computed
  get areNewsAvailable() {
    return this.features.events;
  }

  @computed
  get areAchievementsAvailable() {
    return this.features.achievements;
  }

  @computed
  get teamStartNode() {
    return (
      this.nodes.find(({ owner }) => owner === this.gameData.team_id) || {}
    );
  }

  @computed
  get teamId() {
    return this.gameData.team_id;
  }

  getTeamNameById = id => {
    return this.teamNames[id - 1];
  };

  getTeamColorById = id => {
    return this.teamColors[id - 1];
  };

  getTokenByRole(isCaptain) {
    return this.gameData[isCaptain ? 'captain_link' : 'team_link'].split(
      'token='
    )[1];
  }

  @action
  setToken(token) {
    this.token = token;
  }

  start() {
    if (this.token) {
      this.check();
    } else {
      this.setError({
        header: 'Упс!',
        reason: `Кажется, вы неправильно скопировали ссылку из вашего письма. 
          Откройте письмо и перейдите по ссылке еще раз.`,
      });
    }
  }

  check() {
    this.setFetching(true);
    return check(this.token)
      .then(response => {
        const { status, reason, header } = response.data;

        if (status === 'success') {
          this.setCheked();
        } else {
          this.setError({ reason, header });
        }
      })
      .catch(error => {
        this.setError({ reason: error.message });
      })
      .finally(() => {
        this.setFetching(false);
      });
  }

  @action
  setCheked() {
    this.state = APP_STATES.CHECKED;
  }

  @action
  setFetching(value) {
    this.fetching = value;
  }

  @action
  setName(name) {
    this.name = name;
  }

  @action
  setError({ header, reason } = {}) {
    this.state = APP_STATES.ERROR;

    this.error = {
      header: header || 'Ошибка',
      reason:
        reason || 'Не удалось подключиться к серверу, попробуйте ещё раз.',
    };
  }

  @action.bound
  connect(name) {
    this.setName(name);
    this.setFetching(true);
    return connect(this.token, name)
      .then(response => {
        const { data } = response;

        if (data.status === 'error') {
          this.setError({ header: data.header, reason: data.reason });
          return data.reason;
        }

        if (data.unconsumed_messages) {
          this.chatStore.setUnconsumedMessages(data.unconsumed_messages);
        }

        runInAction(() => {
          this.gameData = response.data;
          this.state = APP_STATES.SIGNED_IN;
        });

        return response.data;
      })
      .catch(() => {
        this.setError();
      })
      .finally(() => {
        this.setFetching(false);
      });
  }

  configureSocket() {
    this.socket = io(WEBSOCKET_API_URL, {
      query: {
        team_room: this.gameData.team_room,
      },
    });
  }

  listenCommonSocket() {
    this.socket.on('restart', () => {
      window.location.reload();
    });
  }

  listenSocket() {
    this.socket.open();

    this.socket.on('update_world', () => {
      this.fetchGame();
      this.fetchNews();
      this.fetchStat();
    });

    this.socket.on('unconsumed_messages', ({ unconsumedMessages }) => {
      this.chatStore.setUnconsumedMessages(unconsumedMessages);
    });

    this.socket.on('update_messages', () => {
      this.chatStore.fetchMessages();
    });

    this.socket.on('update_actions', ({ node_id }) => {
      if (this.selectedNodeId === node_id) {
        this.getActions();
      }
    });

    this.socket.on('final', ({ finalWindow }) => {
      this.fetchGame();
      this.fetchStat();

      if (finalWindow) {
        this.openModal('final');
      } else {
        this.closeModal();
      }
    });
  }

  async fetchGame() {
    const { data } = await game();
    this.setNodes(data.nodes.filter(item => item != null));
    this.setRound(data.info.round);
    this.setIsActive(data.is_active);
  }

  async fetchNews() {
    const { data } = await news(this.token);

    if (isFatalError(data)) {
      this.setError({ reason: data.reason });
      return;
    }

    this.setNewsData(data);
  }

  @action
  setNewsData(value) {
    this.newsData = value;
  }

  @action
  setNodes(nodes) {
    this.nodes = nodes;
  }

  @action
  setRound(value) {
    this.round = value;
  }

  getActions = async () => {
    this.setFetching(true);
    try {
      const { data } = await actions(this.token, this.selectedNodeId);

      if (isFatalError(data)) {
        this.setError({ reason: data.reason, header: data.header });
        return;
      }

      this.fetchGame();
      this.setCommands(data.actions);
    } catch (error) {
      alert(
        'Не удалось получить список комманд, закройте окно команд и попробуйте еще раз>'
      );
    } finally {
      this.setFetching(false);
    }
  };

  addAction = async (type, payload) => {
    this.setFetching(true);
    try {
      const { targetId, value, label } = payload;
      const { data } = await addAction(
        this.token,
        type,
        this.selectedNodeId,
        targetId,
        value,
        label
      );
      if (data.status === 'error') {
        throw new Error('Недостаточно средств для добавления');
      }

      if (isFatalError(data)) {
        this.setError({ reason: data.reason, header: data.header });
        return;
      }

      await this.getActions();
    } catch (error) {
      alert(error);
    } finally {
      this.setFetching(false);
    }
  };

  removeAction = async id => {
    this.setFetching(true);
    try {
      const { data } = await removeAction(this.token, id);

      if (isFatalError(data)) {
        this.setError({ reason: data.reason, header: data.header });
        return;
      }

      await this.getActions();
    } catch (error) {
      alert(error);
    } finally {
      this.setFetching(false);
    }
  };

  @action
  setCommands(value) {
    this.commands = value;
  }

  @computed
  get isUpgradeCommandAdded() {
    return Boolean(
      (this.commands.filter(item => item.type === 'upgrade') || []).length
    );
  }

  @computed
  get isInvestCommandAdded() {
    return Boolean(
      (this.commands.filter(item => item.type === 'invest') || []).length
    );
  }

  @computed
  get isDefenseCommandAdded() {
    return Boolean(
      (this.commands.filter(item => item.type === 'defense') || []).length
    );
  }

  @computed
  get capturedCountries() {
    return (this.commands.filter(item => item.type === 'capture') || []).map(
      item => item.target_id
    );
  }

  @computed
  get transferedCountries() {
    return (this.commands.filter(item => item.type === 'transfer') || []).map(
      item => item.target_id
    );
  }

  async fetchStat() {
    try {
      const { data } = await stat(this.token);

      if (isFatalError(data)) {
        this.setError({ reason: data.reason, header: data.header });
        return;
      }

      this.setLog(data.log || []);
      this.setResults(data.results.sort((a, b) => b.value - a.value));
      this.setActives({
        actives: data.actives,
        activesSum: data.activesSum,
        additionalSum: data.additionalSum,
      });
      this.setAchievements(data.achievements || []);
      this.setCrossAchievements(data.crossAchievements || []);
    } catch (error) {
      alert('Не удалось получить данные статистики');
    }
  }

  @action
  setLog(value) {
    this.log = value;
  }

  @action
  setResults(value) {
    this.results = value;
  }

  @action
  setActives(value) {
    this.actives = value;
  }

  @action
  setAchievements(value) {
    this.achievements = value;
  }

  @action
  setCrossAchievements(value) {
    this.crossAchievements = value;
  }

  @action
  setIsActive(value) {
    this.isActive = value;
  }

  cleanUp() {
    if (this.socket) {
      this.socket.close();
    }
  }

  @action
  openModal = modal => {
    this.showModal = modal;
  };

  @action
  closeModal = () => {
    this.showModal = null;
  };

  @action
  openActionModal = id => {
    this.openModal('action');
    this.selectedNodeId = id;
  };

  @action
  closeActionModal = () => {
    this.closeModal();
    this.selectedNodeId = null;
    this.setCommands([]);
  };

  @computed
  get selectedNode() {
    return this.nodes.find(item => item.id === this.selectedNodeId);
  }

  get storageKey() {
    return `sportmaster-game-last-user-${this.token}`;
  }

  get savedSession() {
    return getStorageItem(this.storageKey);
  }

  saveSession() {
    setStorageItem(this.storageKey, this.name);
  }

  restoreSession = () => {
    this.connect(this.savedSession);
  };
}

export const AppStoreContext = React.createContext(null);

export const useAppStore = () => {
  const appStore = React.useContext(AppStoreContext);

  if (!appStore) {
    throw new Error(
      'useAppStore must be used within a AppStoreContext.Provider.'
    );
  }

  return appStore;
};
