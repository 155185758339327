/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { Container, Row, Col } from 'react-bootstrap';
import Card from '../card/card';

const colProps = {
  xs: 12,
  sm: { span: 10, offset: 1 },
  md: { span: 8, offset: 2 },
};

function Error({ message, title }) {
  return (
    <Container>
      <Row>
        <Col {...colProps}>
          <Card body>
            <h2>{title}</h2>
            <p>{message}</p>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

Error.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
};

Error.defaultProps = {
  message: 'Неизвестная ошибка, обратитесь к администратору.',
  title: 'Ошибка',
};

export default Error;
