import React from 'react';

export const SendIcon = () => (
  <i>
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 3v18M1 11l9-8 9 8" stroke="#fff" strokeWidth="3" />
    </svg>
  </i>
);
