import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './achievement.module.css';

export const AchievementMore = ({ count, onClick }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.achievement}>
      <button type="button" className={styles.more} onClick={onClick}>
        <span className={styles.count}>{count}</span>
        {t('game.stats.allAchievements')}
      </button>
    </div>
  );
};

AchievementMore.propTypes = {
  count: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};
