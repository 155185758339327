import { observable, action, computed, reaction } from 'mobx';
import { messages } from '../api/messages';
import { read } from '../api/read';
import { sendMessage } from '../api/sendMessage';
import { isFatalError } from './helpers/error';

export class ChatStore {
  initialData = [];

  @observable fetching = false;

  @observable users = [];

  @observable selectedChat = null;

  @observable messages = [];

  @observable unconsumedMessages = {};

  constructor(appStore) {
    this.appStore = appStore;

    this.fetchMessages = this.fetchMessages.bind(this);

    reaction(
      () => this.appStore.teams,
      teams => {
        this.setUsers(
          teams
            .map(({ id, name }) => ({ id: Number(id), name }))
            .filter(({ id }) => id !== this.appStore.gameData.team_id)
        );
      }
    );
  }

  @computed
  get isChatOpened() {
    return this.appStore.showModal === 'chat';
  }

  @computed
  get isOwnLastMessage() {
    const lastMessage = this.messages[this.messages.length - 1];
    if (!lastMessage) {
      return undefined;
    }

    return lastMessage.from === this.appStore.gameData.team_id;
  }

  fetchMessages = async () => {
    this.setMessages([]);
    const { data } = await messages(this.appStore.token, this.selectedChat);

    if (isFatalError(data)) {
      this.appStore.setError({ reason: data.reason, header: data.header });
      return;
    }

    this.setMessages(data.messages || []);
  };

  readMessages = async () => {
    const { data } = await read(this.appStore.token, this.selectedChat);

    if (isFatalError(data)) {
      this.appStore.setError({ reason: data.reason, header: data.header });
    }
  };

  sendMessage = async text => {
    try {
      const { data } = await sendMessage(
        this.appStore.token,
        this.selectedChat,
        text,
        this.appStore.name
      );

      if (isFatalError(data)) {
        this.appStore.setError({ reason: data.reason, header: data.header });
        return;
      }

      this.setMessages(data.messages);
    } catch (error) {
      alert('Не удалось отправить сообщение, попробуйте ещё раз.');
    }
  };

  @computed
  get selectedChatName() {
    const selected = this.users.find(({ id }) => id === this.selectedChat);
    return selected ? selected.name : undefined;
  }

  @computed
  get unconsumedMessagesCount() {
    return Object.values(this.unconsumedMessages).reduce(
      (acc, val) => acc + val,
      0
    );
  }

  @action
  setMessages(data) {
    this.messages = data;
  }

  @action.bound
  setSelectedChat(value) {
    this.selectedChat = value;
  }

  @action
  setFetching(value) {
    this.fetching = value;
  }

  @action
  setUsers(value) {
    this.users = value;
  }

  @action
  setUnconsumedMessages(value) {
    this.unconsumedMessages = value;
  }
}
