import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './menu-button.module.css';

export const MenuButton = ({ children, onClick, backgroundColor, color }) => {
  return (
    <button
      type="button"
      className={cn(styles.button, color && styles.teamButton)}
      onClick={onClick}
      style={color ? { backgroundColor, color } : undefined}
    >
      {children}
    </button>
  );
};

MenuButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
};
