import React from 'react';

export const RemoveIcon = () => (
  <i>
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13.5" cy="13.5" r="13.5" fill="#BE5230" />
      <path stroke="#495C31" d="M6 13.5h15" />
    </svg>
  </i>
);
