import React from 'react';

export const DefenseIcon = () => (
  <i>
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3368 2.75843c-1.1403-1.15731-2.9937-.93662-3.9845.29835-.0647.06507-.1118.14852-.1358.23671l-1.7638 6.73741-5.94839 5.9537c-.2036.2038-.2036.5342 0 .738.20364.2038.53367.2038.73736 0l5.67963-5.6846 4.052 4.0557-9.39717 9.4054c-1.55341 1.4869-3.01505-.9506-4.05194-1.8417-.826264-.8661-.44973-1.9364.39947-2.6138.48632-.4706-.26679-1.2248-.73742-.7381.00005-.0001-.399421.3997-.399421.3997-2.013559 2.2124.293011 4.0295 1.840161 5.5318 1.01635 1.0174 2.66996 1.0173 3.68646 0l9.66596-9.6745 6.7315-1.7654c.089-.0239.1708-.0711.2365-.1358 1.2268-.9555 1.4523-2.8848.2978-3.98835.0001.00011-6.9084-6.91452-6.9084-6.91452zm-.7373.73806l6.9085 6.91461c.5796.5569.61 1.5496.0685 2.141l-9.116-9.12413c.6135-.541 1.5529-.51815 2.139.06852zm-4.0981 6.64621l1.4935-5.70468 8.5725 8.58008-5.6996 1.4948-4.3664-4.3702zM22.0245 6.83461c.0825.12778.3589.19493.6012.15287l3.0491-.51654c.2491-.04219.3837-.17903.3007-.30565-.083-.12659-.3522-.19498-.6012-.15286l-3.0491.51651c-.2491.04224-.3837.17908-.3007.30567zM20.1553 5.84471c.2071.20707.5426.20702.7497.00005l2.9397-2.93975c.2071-.20702.2071-.54272 0-.74974-.207-.20703-.5425-.20703-.7497 0l-2.9397 2.93975c-.2071.20697-.2071.54266 0 .74969zM19.1653 3.97533c.1232.08281.2637-.04924.3057-.30065l.5165-3.048932c.0422-.249035-.0262-.518225-.1528-.6012204-.1267-.0830433-.2635.0516228-.3057.3006574l-.5165 3.048925c-.0422.24904.0262.51823.1528.60122z"
        fill="#fff"
      />
      <path
        d="M7.94968 19.8519c.19006.1973.53138.1977.72144 0l4.17948-4.1774c.1992-.1991.1992-.522 0-.7211-.2282-.1522-1.8528-2.058-2.161-1.9489-.1353.0001-.265.0538-.3607.1494l-4.1795 4.1774c-.1992.1991-.1992.522 0 .7211l1.80028 1.7995zm2.73982-5.6164l1.0789 1.0784-.8553.8549-1.07893-1.0784.85533-.8549zm-1.57677 1.576l1.07897 1.0784-1.88138 1.8805-1.07888-1.0785 1.88129-1.8804z"
        fill="#fff"
      />
    </svg>
  </i>
);
