import React from 'react';
import PropTypes from 'prop-types';

export const NodeStart = ({ color = '#705BAE', isScale2x = false }) => (
  <i>
    <svg
      width={isScale2x ? 97 : 77}
      height={isScale2x ? 107 : 87}
      viewBox="0 0 97 107"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M95 42.3296c-.0158 16.71-7.2533 32.5945-19.8631 43.6516l-26.653 18.5808-26.6375-18.581C9.22138 74.9247 2 59.0413 2 42.3477V6.57228l8.1951-1.37867c.0002-.00003.0004-.00007.0006-.0001 25.3575-4.258014 51.2511-4.258014 76.6086 0 .0002.00003.0004.00007.0006.0001L95 6.57228V42.3296z"
        fill={color}
      />
      <path
        d="M95 42.3296c-.0158 16.71-7.2533 32.5945-19.8631 43.6516l-26.653 18.5808-26.6375-18.581C9.22138 74.9247 2 59.0413 2 42.3477V6.57228l8.1951-1.37867c.0002-.00003.0004-.00007.0006-.0001 25.3575-4.258014 51.2511-4.258014 76.6086 0 .0002.00003.0004.00007.0006.0001L95 6.57228V42.3296z"
        fill="#000"
        fillOpacity=".2"
      />
      <path
        d="M95 42.3296c-.0158 16.71-7.2533 32.5945-19.8631 43.6516l-26.653 18.5808-26.6375-18.581C9.22138 74.9247 2 59.0413 2 42.3477V6.57228l8.1951-1.37867c.0002-.00003.0004-.00007.0006-.0001 25.3575-4.258014 51.2511-4.258014 76.6086 0 .0002.00003.0004.00007.0006.0001L95 6.57228V42.3296z"
        stroke={color}
        strokeWidth="4"
      />
    </svg>
  </i>
);

NodeStart.propTypes = {
  color: PropTypes.string.isRequired,
  isScale2x: PropTypes.bool,
};
