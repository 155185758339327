import React from 'react';

export const UpgradeIcon = () => (
  <i>
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 19.8333c5.1547 0 9.3333-4.1786 9.3333-9.3333 0-5.15466-4.1786-9.33333-9.3333-9.33333-5.15466 0-9.33333 4.17867-9.33333 9.33333 0 5.1547 4.17867 9.3333 9.33333 9.3333zM10.5 21C16.299 21 21 16.299 21 10.5 21 4.70101 16.299 0 10.5 0 4.70101 0 0 4.70101 0 10.5 0 16.299 4.70101 21 10.5 21z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3334 10.8889H4.66669v-.7778H16.3334v.7778z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.47223 9.91675H16.5278v1.16665H4.47223V9.91675zm.38889.38885v.3889H16.1389v-.3889H4.86112z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1111 16.3334V4.66675h.7778V16.3334h-.7778z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0833 4.47217V16.5277H9.91665V4.47217h1.16665zm-.3889.38889h-.3889V16.1388h.3889V4.86106z"
        fill="#fff"
      />
    </svg>
  </i>
);
