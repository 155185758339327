import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './empty-node.module.css';
import { NodeControl } from '../../../icons/node-control/node-control';

export const EmptyNode = ({
  label,
  isHighlighted,
  isControlPoint,
  scale = 1,
}) => {
  const isScale2x = scale === 2;
  return (
    <div
      className={cn(styles.nodeLevel0, {
        [styles.highlighted]: isHighlighted,
        [styles.controlPoint]: isControlPoint,
        [styles.scale2x]: isScale2x,
      })}
    >
      {isControlPoint && (
        <div className={styles.icon}>
          <NodeControl isScale2x={isScale2x} />
        </div>
      )}
      <span className={styles.label}>{label}</span>
    </div>
  );
};

EmptyNode.propTypes = {
  label: PropTypes.string.isRequired,
  isHighlighted: PropTypes.bool,
  isControlPoint: PropTypes.bool,
  scale: PropTypes.number,
};
