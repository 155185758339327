import React from 'react';
import PropTypes from 'prop-types';

import styles from './achievement.module.css';
import * as images from './images';

export const Achievement = ({ id, name }) => {
  return (
    <div className={styles.achievement}>
      <img
        className={styles.img}
        src={images[`achievement${id}`]}
        alt={id}
        title={name}
      />
      <span className={styles.label}>Достижение</span>
      <span className={styles.name}>{name}</span>
    </div>
  );
};

Achievement.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};
