import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import styles from './month.module.css';
import { getRoundName } from '../../helpers';

export const Round = observer(({ round }) => {
  const { t } = useTranslation();
  const monthName = getRoundName(round);
  return (
    <div className={styles.month}>
      <div className={styles.monthName}>{monthName}</div>
      <div className={styles.round}>
        <div className={styles.roundValue}>{round}</div>
        {t('game.round.day')}
      </div>
    </div>
  );
});

Round.propTypes = {
  round: PropTypes.number.isRequired,
};
