import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from '../command.module.css';
import { AddIcon } from '../../../icons/add/add';
import { InputCounter } from '../../input-counter/input-counter';

export const CommandWithInput = ({ label, onAdd, availableMoney, Icon }) => {
  const [value, setValue] = useState(0);
  const handleChange = v => {
    setValue(v);
  };
  const handleSubmit = () => {
    onAdd(value, label);
    setValue(0);
  };
  const disabled = !value || availableMoney < value;
  return (
    <div className={styles.command}>
      <span className={styles.icon}>
        <Icon />
      </span>
      <span className={styles.label}>{label}</span>
      <InputCounter
        initialValue={value}
        onChange={handleChange}
        max={availableMoney}
      />
      <button
        type="button"
        onClick={handleSubmit}
        className={styles.button}
        disabled={disabled}
      >
        <AddIcon />
      </button>
    </div>
  );
};

CommandWithInput.propTypes = {
  onAdd: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  availableMoney: PropTypes.number.isRequired,
  Icon: PropTypes.node.isRequired,
};
