import React from 'react';
import PropTypes from 'prop-types';

export const NodeControl = ({
  color = '#83745F',
  isScale2x = false,
  className,
}) => {
  return (
    <i className={className}>
      <svg
        width={isScale2x ? 94 : 74}
        height={isScale2x ? 107 : 87}
        viewBox="0 0 94 107"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M93.4194 76.686V30.3057c0-2.2758-1.2198-4.3751-3.2069-5.5326L49.9204 1.58293c-1.9871-1.137921-4.4266-1.137921-6.394 0L3.23422 24.7731C1.24716 25.911.0273731 28.0103.027373 30.3057L.0273709 76.7056c-1e-7 2.2759 1.2197891 4.3752 3.2068491 5.5327L43.5264 105.428c1.987 1.138 4.4266 1.138 6.394 0l40.2921-23.2093c1.9871-1.1379 3.2069-3.2372 3.2069-5.5327z"
          fill={color}
        />
        <path
          d="M43.5264 105.428c1.987 1.138 4.4266 1.138 6.394 0l40.2921-23.2093c1.9871-1.1379 3.2069-3.2372 3.2069-5.5327V30.3057c0-2.2758-1.2198-4.3751-3.2069-5.5326M43.5264 105.428l.4988-.866-.0019-.001m-.4969.867L3.23422 82.2383M43.5264 105.428l.4969-.867m0 0c1.6808.962 3.7403.96 5.3964.002l.0015-.001 40.2922-23.2098.0022-.0013c1.6762-.9599 2.7038-2.7283 2.7038-4.6649V30.3057c0-1.913-1.0245-3.6854-2.7079-4.6672l.0022.0013.4988-.8667M44.0233 104.561L3.73758 81.3742l-.50336.8641M90.2125 24.7731l-.5033.8641L49.4234 2.45071l.497-.86778M90.2125 24.7731L49.9204 1.58293m0 0c-1.9871-1.137921-4.4266-1.137921-6.394 0L3.23422 24.7731C1.24716 25.911.0273731 28.0103.027373 30.3057L.0273709 76.7056c-1e-7 2.2759 1.2197891 4.3752 3.2068491 5.5327M49.9204 1.58293l-.4988.8667.0009.00057c-1.6806-.962-3.7396-.95935-5.3955-.00163l-.0018.00107L3.73305 25.6398l-.00188.0011c-1.67625.9599-2.7038 2.7283-2.7038 4.6648v46.3999c0 1.9131 1.02451 3.6856 2.70799 4.6674l-.00231-.0014-.49883.8667"
          stroke="#fff"
          strokeOpacity=".15"
          strokeWidth="2"
        />
      </svg>
    </i>
  );
};

NodeControl.propTypes = {
  color: PropTypes.string.isRequired,
  isScale2x: PropTypes.bool,
  className: PropTypes.string,
};
