import React from 'react';

export const CaptureIcon = () => (
  <i>
    <svg
      width="15"
      height="19"
      viewBox="0 0 15 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4289 6.67908l3.4498-4.23458c.1429-.17549.1612-.42151.0459-.61622-.1153-.19475-.3398-.2969-.5624-.25592-2.6723.49211-4.73315-.13923-6.72619-.749656C5.27781.100342 3.05039-.581946.298515.794008.115575.885496 0 1.07247 0 1.27701V16.6919c0 .1433.0569953.2807.158348.382l1.203892 1.2026c.10545.1053.24353.1579.38165.1579.13811 0 .27622-.0527.38164-.158l1.20382-1.2025c.10139-.1013.15835-.2387.15835-.382V10.111c1.27291-.0132 2.49825.3621 3.83197.7705 1.51141.463 3.17373.9722 5.14443.9722.6611 0 1.3571-.0574 2.0936-.193.1891-.0348.3453-.1674.4104-.3484.065-.1809.029-.3827-.0946-.5299l-3.4446-4.10332zM2.40774 16.468l-.66382.6631-.66389-.6631v-5.8225c.45604-.2026.89684-.3401 1.32771-.4248v6.2473zm5.22831-6.61911c-1.3649-.41808-2.6857-.82263-4.08999-.82263-.78814 0-1.603.1276-2.46603.45175V1.61905c2.2315-.991492 4.09557-.42049 6.23972.2363 1.69409.5189 3.57785 1.09598 5.87295.9488l-2.8831 3.53904c-.1637.20091-.1615.48977.0051.6883l3.1046 3.69831c-2.2266.2087-4.03105-.3442-5.78325-.88091z"
        fill="#fff"
      />
    </svg>
  </i>
);
