import React from 'react';

import { Spinner } from '../spinner/spinner';
import classes from './page-spinner.module.css';

export const PageSpinner = () => (
  <div className={classes.container}>
    <Spinner />
  </div>
);
