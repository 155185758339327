/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../node/node.module.css';
import { NodeStart } from '../../../icons/node-start/node-start';
import { NodeIcon } from '../../../icons/node/node';
import { NodeControl } from '../../../icons/node-control/node-control';
import { NodeControlFlag } from '../../../icons/node-control/node-control-flag';
import bg1 from '../../../assets/icecastle-1.png';
import bg2 from '../../../assets/icecastle-2.png';
import bg3 from '../../../assets/icecastle-3.png';
import bg4 from '../../../assets/icecastle-4.png';
import bg5 from '../../../assets/icecastle-5.png';

const nodeIcon = [bg1, bg2, bg3, bg4, bg5];

const NodeControlIcon = props => {
  return (
    <div className={styles.nodeControlIcon}>
      <NodeControlFlag className={styles.flag} {...props} />
      <NodeControl className={styles.icon} {...props} />
    </div>
  );
};

export const NodeWithLevel = ({
  label,
  price,
  color,
  level,
  defense,
  isHighlighted,
  isOwnNode,
  isStartNode,
  isControlPoint,
  scale = 1,
}) => {
  let Icon = NodeIcon;
  const isScale2x = scale === 2;

  if (isStartNode) {
    Icon = NodeStart;
  } else if (isControlPoint) {
    Icon = NodeControlIcon;
  }
  return (
    <div
      className={cn(styles.nodeWithLevel, {
        [styles.own]: isOwnNode,
        [styles.highlighted]: isHighlighted,
        [styles.scale2x]: isScale2x,
      })}
    >
      {label && <div className={styles.label}>{label}</div>}
      <div
        className={cn(
          styles.nodeIcon,
          styles[`level${level}`],
          isScale2x && styles.scale2x
        )}
        style={{ backgroundImage: `url(${nodeIcon[level - 1]})` }}
      />
      <div className={styles.price}>{price}</div>
      {defense && <div className={styles.defense}>{defense}</div>}
      <span className={styles.icon}>
        <span className={styles.level}>{level}</span>
        <Icon isScale2x={isScale2x} color={color} />
      </span>
    </div>
  );
};

NodeWithLevel.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  level: PropTypes.number.isRequired,
  defense: PropTypes.number.isRequired,
  isHighlighted: PropTypes.bool,
  isOwnNode: PropTypes.bool,
  isStartNode: PropTypes.bool,
  isControlPoint: PropTypes.bool,
  scale: PropTypes.number,
};
