import React from 'react';

import styles from './clouds.module.css';

export const Clouds = () => (
  <>
    <div className={styles.dark} />
    <div className={styles.clouds} />
    <div className={styles.cloudsBottom} />
    <div className={styles.frozenPattern}>
      <div className={styles.stars} />
      <div className={styles.frozenPattern1} />
      <div className={styles.frozenPattern2} />
      <div className={styles.frozenPattern3} />
      <div className={styles.frozenPattern4} />
    </div>
  </>
);
