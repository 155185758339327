import React from 'react';

export const CheckIcon = () => (
  <i>
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 21.965H8L0 9.96497l3.5-2 6 9.50003L18 .964966l3.5 2.000004-10 19.00003z"
        fill="#fff"
      />
    </svg>
  </i>
);
