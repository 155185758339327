import React from 'react';

export const ArrowDownIcon = () => (
  <i>
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 7l-7 6.3474L17 20"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <circle
        cx="13.5"
        cy="13.5"
        r="13"
        transform="rotate(-180 13.5 13.5)"
        stroke="#fff"
      />
    </svg>
  </i>
);
