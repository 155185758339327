import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';

import styles from '../command.module.css';
import { AddIcon } from '../../../icons/add/add';
import { InputCounter } from '../../input-counter/input-counter';
import { formatCurrency } from '../../../helpers';

export const CommandWithSelectAndInput = ({
  onAdd,
  options,
  label,
  availableMoney,
  withMinMoney = false,
  Icon,
}) => {
  const [select, setSelect] = useState(options[0]);
  const [value, setValue] = useState(withMinMoney ? options[0].money || 0 : 0);
  const handleChange = v => {
    setValue(v);
  };
  const submit = () => {
    onAdd(value, select.value, `${label} ${select.label}`);
    setValue(0);
  };
  const disabled = !Number(value) || !select.value || availableMoney < value;

  useEffect(() => {
    if (!options.includes(select.label)) {
      setSelect(options[0]);
    }
  }, [options]);
  return (
    <div className={styles.command}>
      <span className={styles.icon}>
        <Icon />
      </span>
      <span className={styles.label}>{label}</span>
      <Dropdown className={styles.select}>
        <Dropdown.Toggle variant="secondary">
          <span className={styles.label}>{select.label}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {options.map(option => (
            <Dropdown.Item
              key={option.value}
              onClick={() => {
                setSelect(option);

                if (withMinMoney) {
                  const minPrice = option.money || 0;
                  if (minPrice > value || !minPrice) {
                    setValue(minPrice);
                  }
                }
              }}
            >
              {withMinMoney && option.money
                ? `${option.label}, минимум ${formatCurrency(option.money)}`
                : option.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <InputCounter
        key={select.value}
        initialValue={value}
        onChange={handleChange}
        max={availableMoney}
      />
      <button
        type="button"
        onClick={submit}
        className={styles.button}
        disabled={disabled}
      >
        <AddIcon />
      </button>
    </div>
  );
};

CommandWithSelectAndInput.propTypes = {
  onAdd: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  availableMoney: PropTypes.number.isRequired,
  options: PropTypes.arrayOf({
    label: PropTypes.string,
    value: PropTypes.number.isRequired,
    money: PropTypes.number,
  }),
  withMinMoney: PropTypes.bool,
  Icon: PropTypes.node.isRequired,
};
