import { api } from '.';

export const sendMessage = (token, teamId, text, author) =>
  api.get('/send_message', {
    params: {
      token,
      team_id: teamId,
      text,
      author,
    },
  });
