import React from 'react';
import PropTypes from 'prop-types';

export const NodeIcon = ({ color = '#705BAE', isScale2x = false }) => (
  <i>
    <svg
      width={isScale2x ? 106 : 86}
      height={isScale2x ? 95 : 75}
      viewBox="0 0 106 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.96624 49.6754l-.00213-.0037c-.77753-1.3443-.78281-3.0229.00331-4.3971.00032-.0005.00065-.0011.00098-.0017L26.1511 4.99359c.0008-.00139.0017-.00278.0025-.00417.8058-1.38005 2.2516-2.20939 3.8021-2.20939h46.3802c1.5777 0 3.0152.83529 3.7971 2.20075l.0026.00439L103.345 45.2773l.002.0031c.778 1.3442.783 3.0228-.003 4.397-.001.0005-.001.0011-.001.0017L80.1601 89.9585c-.0008.0014-.0017.0028-.0025.0042-.8059 1.38-2.2516 2.2093-3.8021 2.2093H29.9557c-1.5777 0-3.0152-.8353-3.7972-2.2007l-.0021-.0038L2.96624 49.6754z"
        fill={color}
      />
      <path
        d="M2.96624 49.6754l-.00213-.0037c-.77753-1.3443-.78281-3.0229.00331-4.3971.00032-.0005.00065-.0011.00098-.0017L26.1511 4.99359c.0008-.00139.0017-.00278.0025-.00417.8058-1.38005 2.2516-2.20939 3.8021-2.20939h46.3802c1.5777 0 3.0152.83529 3.7971 2.20075l.0026.00439L103.345 45.2773l.002.0031c.778 1.3442.783 3.0228-.003 4.397-.001.0005-.001.0011-.001.0017L80.1601 89.9585c-.0008.0014-.0017.0028-.0025.0042-.8059 1.38-2.2516 2.2093-3.8021 2.2093H29.9557c-1.5777 0-3.0152-.8353-3.7972-2.2007l-.0021-.0038L2.96624 49.6754z"
        fill="#000"
        fillOpacity=".2"
      />
      <path
        d="M2.96624 49.6754l-.00213-.0037c-.77753-1.3443-.78281-3.0229.00331-4.3971.00032-.0005.00065-.0011.00098-.0017L26.1511 4.99359c.0008-.00139.0017-.00278.0025-.00417.8058-1.38005 2.2516-2.20939 3.8021-2.20939h46.3802c1.5777 0 3.0152.83529 3.7971 2.20075l.0026.00439L103.345 45.2773l.002.0031c.778 1.3442.783 3.0228-.003 4.397-.001.0005-.001.0011-.001.0017L80.1601 89.9585c-.0008.0014-.0017.0028-.0025.0042-.8059 1.38-2.2516 2.2093-3.8021 2.2093H29.9557c-1.5777 0-3.0152-.8353-3.7972-2.2007l-.0021-.0038L2.96624 49.6754z"
        stroke={color}
        strokeWidth="4"
      />
    </svg>
  </i>
);

NodeIcon.propTypes = {
  color: PropTypes.string.isRequired,
  isScale2x: PropTypes.bool,
};
