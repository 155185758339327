import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Button } from 'react-bootstrap';
import styles from './welcome-modal.module.css';
import { Modal } from '../../components/modal/modal';
import { useAppStore } from '../../store/app';
import logo from '../../assets/logo.png';
import line from '../../assets/line-small.svg';

export const WelcomeModal = observer(() => {
  const {
    showModal,
    closeModal,
    teamColor,
    teamColorName,
    teamName,
    teamStartNode,
  } = useAppStore();
  const { t } = useTranslation();
  return (
    <Modal
      show={showModal === 'welcome'}
      onHide={closeModal}
      size="lg"
      closable={false}
      dialogClassName={styles.modal}
      withDecorations
    >
      <div className={styles.modalContainer}>
        <div className={styles.logo}>
          <img src={logo} alt={t('game.name')} className={styles.logoImage} />
        </div>
        <div className={styles.gameName}>{t('game.name')}</div>
        <div className={styles.gameDescr}>
          Командная тактическая
          <br />
          игра <img src={line} alt="game" />
        </div>
        <div className={styles.title}>Приветствуем вас в игре!</div>
        <div className={styles.description}>
          Цвет вашей команды на игровом поле —{' '}
          <span
            className={styles.teamColor}
            style={{
              backgroundColor: teamColor,
              // color: getStrokeColorByColor(TEAM_COLORS[team_id]),
            }}
          >
            {teamColorName}
          </span>
          <br />
          Вы играете за команду <strong>{teamName}</strong>
          <br />
          Ваша стартовая крепость: <strong>{teamStartNode.name}</strong>
          <br />
          <br />
          Ваша задача - набрать как можно больше очков, чтобы стать лучшей
          командой. Для этого вы можете:
          <ul className={styles.list}>
            <li>захватывать снежные крепости </li>
            <li>развивать захваченные крепости </li>
            <li>
              получать дополнительные очки за захват и удержание крепостей в
              центре поля
            </li>
          </ul>
        </div>
        <Button className={styles.button} onClick={closeModal}>
          Начать игру
        </Button>
      </div>
    </Modal>
  );
});
