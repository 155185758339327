import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../command.module.css';
import { RemoveIcon } from '../../../icons/remove/remove';
import { formatCurrency } from '../../../helpers';

export const AddedCommand = ({ id, label, value, onRemove, Icon }) => {
  return (
    <div className={cn(styles.command, styles.removeCommand)}>
      <span className={styles.icon}>
        <Icon />
      </span>
      {label}
      <span className={styles.value}>{formatCurrency(value)}</span>
      <button
        type="button"
        onClick={() => onRemove(id)}
        className={styles.button}
      >
        <RemoveIcon />
      </button>
    </div>
  );
};

AddedCommand.propTypes = {
  onRemove: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  Icon: PropTypes.node.isRequired,
};
