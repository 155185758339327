/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Card as BootstrapCard } from 'react-bootstrap';
import cn from 'classnames';

import classes from './card.module.css';

const Card = ({ children, className, ...props }) => {
  return (
    <BootstrapCard className={cn(classes.card, className)} {...props}>
      {children}
    </BootstrapCard>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Card;
