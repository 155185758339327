import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import classes from './header.module.css';
import { Menu } from '../menu/menu';
// import { Logo } from '../../icons/logo/logo';
// import { useAppStore } from '../../store/app';
import logo from '../../assets/logo.png';

function Header() {
  // const { teamColor } = useAppStore();
  const { t } = useTranslation();
  return (
    <div className={classes.header}>
      <div className={cn(classes.logo)}>
        <img src={logo} alt={t('game.name')} className={classes.logoImage} />
        <div className={classes.logoText}>
          <div className={classes.name}>{t('game.name')}</div>
          <div className={classes.subname}>
            Командная тактическая
            <br />
            online-игра
          </div>
        </div>
      </div>
      <div className={classes.menu}>
        <Menu />
      </div>
      <div className={classes.round}></div>
    </div>
  );
}

Header.propTypes = {};

export default observer(Header);
