/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';

import { Table } from 'react-bootstrap';
import styles from './help.module.css';
import { Modal } from '../../components/modal/modal';
import { useAppStore } from '../../store/app';
import image1 from '../../assets/help/1.jpg';
import image2 from '../../assets/help/2.jpg';
import image3 from '../../assets/help/3.jpg';
import image4 from '../../assets/help/4.jpg';
import image5 from '../../assets/help/5.jpg';
import image6 from '../../assets/help/6.jpg';
import image7 from '../../assets/help/7.jpg';
import { Node } from '../../components/map/node/node';

export const HelpModal = observer(() => {
  const { showModal, closeModal } = useAppStore();
  const { t } = useTranslation();
  const renderView = ({ ...props }) => (
    <div className={styles.view} {...props} />
  );
  return (
    <Modal
      show={showModal === 'help'}
      onHide={closeModal}
      size="lg"
      dialogClassName={styles.modal}
      withDecorations
    >
      <Modal.Title icon="help">{t('game.help.title')}</Modal.Title>
      <div className={styles.block}>
        <Scrollbars
          style={{ width: '100%' }}
          autoHeight
          autoHeightMin={300}
          autoHeightMax={500}
          renderView={renderView}
        >
          <p>
            <strong>Главная цель игры</strong> — заработать как можно больше
            победных баллов, захватывая территорию и воздвигая снежные крепости.
          </p>
          <p>
            Главная «валюта» в игре — это снег. Из снега можно лепить снежки,
            чтобы атаковать соседние крепости. Также снег можно использовать как
            строительный материал для укрепления ваших крепостей.
          </p>
          <p>
            <strong>Как можно заработать баллы?</strong>
          </p>
          <p>Вы начинаете игру в одной из стартовых точек, и можете:</p>
          <ol>
            <li>
              <strong>Захватывать новые точки на карте.</strong> Чем больше
              точек вы контролируете, тем больше баллов зарабатываете
            </li>

            <li>
              <strong>Развивать крепости в уже захваченных точках.</strong> Чем
              выше уровень развития ваших снежных крепостей, тем больше снега
              они вам приносят, и тем сложнее противникам их будет захватить.
            </li>

            <li>
              <strong>Завоевывать переходящие призы.</strong> В игре вы будете
              получать дополнительные баллы за различные достижения. Подробнее
              об этом можно прочитать ниже.
            </li>
          </ol>
          <p>
            <strong>Как захватить новую точку?</strong>
          </p>
          <ol>
            <li>На карте местности кликаете на одну ваших крепостей</li>

            <li>
              В выпадающем окне находите опцию{' '}
              <strong>«Захватить точку »</strong>
            </li>

            <li>
              В выпадающем списке выбираете соседнюю точку, которую планируете
              атаковать
            </li>

            <li>
              Выбираете количество снежков, которое вы собираетесь потратить на
              атаку
            </li>

            <li>
              Нажимаете на голубую кнопку «<strong>+</strong>» напротив
              выбранного действия
            </li>
          </ol>
          <p>
            В любое время до конца раунда вы можете отменить это решение, нажав
            на красную кнопку «<strong>-</strong>» напротив соответствующего
            решения в разделе «<strong>Принятые решения</strong>».
          </p>

          <table className={styles.centeredTable}>
            <tr>
              <td>
                <img
                  className={styles.image}
                  src={image1}
                  width=""
                  alt="alt_text"
                />

                <p>
                  Из крепости «Форт-Апрель» планируется атака на точку «Плоский
                  холм»
                </p>
              </td>
              <td>
                <img
                  className={styles.image}
                  src={image2}
                  width=""
                  alt="alt_text"
                />

                <p>
                  Отмена атаки из крепости «Форт-Апрель» на точку «Плоский холм»
                </p>
              </td>
            </tr>
          </table>

          <p>
            <strong>Как развивать точку?</strong>
          </p>
          <ol>
            <li>На карте местности кликаете на одну ваших крепостей</li>

            <li>
              В выпадающем окне находите опцию «
              <strong>Расширить крепость»</strong>» и нажимаете на голубую
              кнопку «<strong>+</strong>» напротив выбранного действия
            </li>
          </ol>
          <p>
            Если у вас будет достаточно снега для совершения этого действия, оно
            будет принято к исполнению. В любое время до конца раунда вы можете
            отменить это решение, нажав на красную кнопку «-» напротив
            соответствующего действия в разделе «Принятые решения».
          </p>

          <table className={styles.centeredTable}>
            <tr>
              <td>
                <img
                  className={styles.image}
                  src={image3}
                  width=""
                  alt="alt_text"
                />
                <p>
                  Планируется развить крепость «Плоский холм» со 2-го до 3-го
                  уровня
                </p>
              </td>
              <td>
                <img
                  className={styles.image}
                  src={image4}
                  width=""
                  alt="alt_text"
                />

                <p>
                  Команда передумала разввать крепость «Плоский холм» со 2-го до
                  3-го уровня
                </p>
              </td>
            </tr>
          </table>

          <p>
            <strong>Где посмотреть статистику?</strong>
          </p>
          <p>
            Во вкладке «Моя команда» вы можете увидеть рейтинг команд, узнать
            результаты ваших действий в прошлом раунде, получить данные о
            захваченных точках, выяснить, какие крепости на каком уровне
            развития находятся и сколько победных баллов вам приносят.
          </p>

          <table className={styles.centeredTable}>
            <tr>
              <td>
                <img
                  className={styles.image}
                  src={image5}
                  width=""
                  alt="alt_text"
                />
                <p>
                  Во вкладке «Моя команда» можно увидеть всю статистику: рейтинг
                  команд, список захваченных точек, список событий прошедшего
                  раунда
                </p>
              </td>
              <td></td>
            </tr>
          </table>

          <p>
            <strong>Что еще можно делать в игре?</strong>
          </p>
          <ul>
            <li>
              <strong>Перераспределять запасы снега</strong>. Вы можете
              распределять запасы снега между своими крепостями. Отправленные
              припасы перейдут на другую точку в начале следующего раунда.
            </li>

            <li>
              <strong>Общаться с другими командами</strong>. Во вкладке «Чаты»
              вы можете вступить в диалог с любой другой командой, чтобы
              обменяться новостями или заключить союз. Например, чтобы совместно
              атаковать третью команду, либо наоборот, договориться о
              ненападении друг на друга.
            </li>
          </ul>
          <table className={styles.centeredTable}>
            <tr>
              <td>
                <img
                  className={styles.image}
                  src={image6}
                  width=""
                  alt="alt_text"
                />

                <p>
                  Команда планирует перевести 50 единиц снега из крепости «Форт
                  Апрель» в крепость «Плоский холм»
                </p>
              </td>
              <td>
                <img
                  className={styles.image}
                  src={image7}
                  width=""
                  alt="alt_text"
                />
                <p>
                  Во вкладке «Чаты» можно общаться с другими командами и
                  договариваться о совместных операциях
                </p>
              </td>
            </tr>
          </table>

          <p>
            <strong>Игровые коэффициенты</strong>
          </p>

          <p>
            Обратите внимание! Крепости <strong>вне центра поля</strong>{' '}
            приносят больше снега, но меньше победных баллов, а крепости{' '}
            <strong>в центре поля</strong> - меньше снега, зато больше баллов!
            Контролируйте крепости вне замка и тратьте полученный снег на захват
            крепостей внутри его!
          </p>

          <p>
            <strong>Обычные крепости</strong>
          </p>

          <Table>
            <tr>
              <td>
                <strong>Уровень 1</strong>
              </td>
              <td>
                <strong>Уровень 2</strong>
              </td>
              <td>
                <strong>Уровень 3</strong>
              </td>
              <td>
                <strong>Уровень 4</strong>
              </td>
              <td>
                <strong>Уровень 5</strong>
              </td>
            </tr>
            <tr className={styles.levels}>
              <td>
                <Node level={1} />
              </td>
              <td>
                <Node level={2} />
              </td>
              <td>
                <Node level={3} />
              </td>
              <td>
                <Node level={4} />
              </td>
              <td>
                <Node level={5} />
              </td>
            </tr>
            <tr>
              <td colSpan="5">
                <strong>Сколько снега приносит</strong>
              </td>
            </tr>
            <tr>
              <td>50</td>
              <td>150</td>
              <td>300</td>
              <td>500</td>
              <td>800</td>
            </tr>
            <tr>
              <td colSpan="5">
                <strong>Сколько снега нужно для захвата</strong>
              </td>
            </tr>
            <tr>
              <td>50</td>
              <td>150</td>
              <td>300</td>
              <td>500</td>
              <td>800</td>
            </tr>
            <tr>
              <td colSpan="5">
                <strong>
                  Сколько снега нужно для развития до следующего уровня
                </strong>
              </td>
            </tr>
            <tr>
              <td>50</td>
              <td>200</td>
              <td>500</td>
              <td>1000</td>
              <td>нет</td>
            </tr>
            <tr>
              <td colSpan="5">
                <strong>Сколько победных баллов приносит</strong>
              </td>
            </tr>
            <tr>
              <td>1 балл</td>
              <td>2 балла</td>
              <td>5 баллов</td>
              <td>10 баллов</td>
              <td>20 баллов</td>
            </tr>
          </Table>

          <p>
            <strong>Крепости в центре поля</strong>
          </p>

          <Table>
            <tr>
              <td>
                <strong>Уровень 1</strong>
              </td>
              <td>
                <strong>Уровень 2</strong>
              </td>
              <td>
                <strong>Уровень 3</strong>
              </td>
              <td>
                <strong>Уровень 4</strong>
              </td>
              <td>
                <strong>Уровень 5</strong>
              </td>
            </tr>
            <tr className={styles.levels}>
              <td>
                <Node level={1} isControlPoint />
              </td>
              <td>
                <Node level={2} isControlPoint />
              </td>
              <td>
                <Node level={3} isControlPoint />
              </td>
              <td>
                <Node level={4} isControlPoint />
              </td>
              <td>
                <Node level={5} isControlPoint />
              </td>
            </tr>
            <tr>
              <td colSpan="5">
                <strong>Сколько снега приносит</strong>
              </td>
            </tr>
            <tr>
              <td>30</td>
              <td>70</td>
              <td>120</td>
              <td>200</td>
              <td>300</td>
            </tr>
            <tr>
              <td colSpan="5">
                <strong>Сколько снега нужно для захвата</strong>
              </td>
            </tr>
            <tr>
              <td>50</td>
              <td>150</td>
              <td>300</td>
              <td>500</td>
              <td>800</td>
            </tr>
            <tr>
              <td colSpan="5">
                <strong>
                  Сколько снега нужно для развития до следующего уровня
                </strong>
              </td>
            </tr>
            <tr>
              <td>50</td>
              <td>200</td>
              <td>500</td>
              <td>1000</td>
              <td>нет</td>
            </tr>
            <tr>
              <td colSpan="5">
                <strong>Сколько победных баллов приносит</strong>
              </td>
            </tr>
            <tr>
              <td>2 балла</td>
              <td>5 баллов</td>
              <td>12 баллов</td>
              <td>25 баллов</td>
              <td>50 баллов</td>
            </tr>
          </Table>

          <p>
            <strong>Список переходящих призов</strong>
          </p>
          <p>
            В игре есть несколько переходящих призов. Каждый из них приносит
            дополнительные победные баллы.
          </p>
          <ul>
            <li>
              <strong>За широкую экспансию</strong> (самое большое число
              захваченных точек вне центра поля) — 25 баллов
            </li>

            <li>
              <strong>За интенсивное развитие</strong> (самое большое число
              точек 5-го уровня) — 25 баллов
            </li>

            <li>
              <strong>За доминирование</strong> (самое большое число захваченных
              контрольных точек в центре поля) — 25 баллов
            </li>
          </ul>
          <p>Удачной игры!</p>
        </Scrollbars>
      </div>
    </Modal>
  );
});
