/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';

import styles from './buildings-info.module.css';

export const BuildingsInfo = ({ tableData, summary, additionalSum }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <Scrollbars style={{ width: '100%', height: '100%' }} height={300}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.type}>{t('game.stats.capturedNodes')}</th>
              <th>{t('game.stats.level')}</th>
              <th>{t('game.stats.score')}</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map(({ node_name, level, winPoints }) => {
              return (
                <tr key={node_name}>
                  <td className={styles.type}>{node_name}</td>
                  <td>{level}</td>
                  <td>{winPoints}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Scrollbars>
      <div className={styles.summary}>
        <div>
          {`${t('game.stats.totalScore')}:`}{' '}
          <span className={styles.value}>{summary}</span>
        </div>
        <div>
          {`${t('game.stats.additionalScore')}:`}{' '}
          <span className={styles.value}>{additionalSum}</span>
        </div>
      </div>
    </div>
  );
};

BuildingsInfo.propTypes = {
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      node_name: PropTypes.string.isRequired,
      level: PropTypes.number.isRequired,
      winPoints: PropTypes.number.isRequired,
    })
  ),
  summary: PropTypes.number.isRequired,
  additionalSum: PropTypes.number.isRequired,
};
