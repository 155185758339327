/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal as BootstrapModal } from 'react-bootstrap';
import cn from 'classnames';

import styles from './modal.module.css';
import { CloseIcon } from '../../icons/close/close';

import helpIcon from '../../assets/help.svg';
import newsIcon from '../../assets/news.svg';
import insidesIcon from '../../assets/insides.svg';
import statIcon from '../../assets/stat.svg';

const icons = {
  help: helpIcon,
  news: newsIcon,
  insides: insidesIcon,
  stat: statIcon,
};

export const Modal = ({
  show,
  onHide,
  children,
  closable = true,
  backgroundImage,
  dialogClassName,
  withDecorations,
  ...rest
}) => {
  return (
    <BootstrapModal
      show={show}
      onHide={onHide}
      centered
      backdrop={false}
      contentClassName={styles.content}
      dialogClassName={cn(
        styles.dialog,
        withDecorations && styles.dialogWithDecorations,
        dialogClassName
      )}
      {...rest}
    >
      {closable && (
        <button type="button" className={styles.close} onClick={onHide}>
          <CloseIcon />
        </button>
      )}
      <div className={withDecorations && styles.withDecorations} />
      <BootstrapModal.Body
        style={
          backgroundImage
            ? {
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: '50%',
              }
            : undefined
        }
      >
        {children}
      </BootstrapModal.Body>
    </BootstrapModal>
  );
};

Modal.Title = ({ children, icon }) => (
  <div className={styles.title}>
    {icon && (
      <span className={styles.icon}>
        <img src={icons[icon]} alt="icon" />
      </span>
    )}
    {children}
  </div>
);

Modal.Subtitle = ({ children, icon }) => (
  <div className={styles.subtitle}>
    {icon && (
      <span className={styles.icon}>
        <img src={icons[icon]} alt="icon" />
      </span>
    )}
    {children}
  </div>
);

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  children: PropTypes.node,
  closable: PropTypes.bool,
  backgroundImage: PropTypes.string,
  dialogClassName: PropTypes.string,
  withDecorations: PropTypes.bool,
};

Modal.Title.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.string,
};

Modal.Subtitle.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.string,
};
